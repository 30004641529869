<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header.vue";
import Axios from "@/plugins/axios";
import DataLoader from "@/components/data-loader.vue";
import Pagination from "@/components/dashboard/Pagination.vue";
import TownTableCard from "@/components/dashboard/town/town-table-card.vue";
import TownFormModal from "@/components/dashboard/town/town-form-modal.vue";
import {inject} from "vue";

export default {
  components: {
    TownFormModal,
    TownTableCard,
    Pagination,
    DataLoader,
    Layout,
    PageHeader
  },
  setup(){
    const globals = inject("globals");

    return {globals}
  },
  data() {
    return {
      towns: [],
      showDataLoader: true,
      entityFormModal: false,
      activeTown: null,
      next: null,
      previous: null,
      page: 1,
      totalItems: 0,
      query: '',
      url: ''
    }
  },
  mounted() {
    this.initUrl()
    this.getTowns()
  },
  methods: {
    openAddTownModal(){
      this.entityFormModal = true
    },
    openUpdateTownModal(t){
      this.activeTown = t
      this.entityFormModal = true
    },
    closeModal(type){
      switch (type) {
        case 'town-form':
          this.activeTown = null
          this.entityFormModal = false
          break
        default:
          break
      }
    },
    updateTownTable(town){
      this.towns = this.towns.filter(t => t.id !== town.id)
      this.towns.unshift(town)
    },
    getTowns(){
      Axios.get(this.url)
          .then(response => {
            this.setPagination(response)
            response.data["hydra:member"].map(p => {
              this.towns.push(p)
            })

          })
          .catch(() => {})
          .finally(() => {
            this.showDataLoader = false
          })
    },
    initUrl(){
      this.url = 'admin/cities?page=' + this.page + '&query=' + this.query
    },
    setPagination(response){
      if (response.data["hydra:view"]) {
        if (response.data["hydra:view"]["hydra:next"]){

          this.totalItems = response.data["hydra:totalItems"]
          this.next = response.data["hydra:view"]["hydra:next"]
          this.previous = response.data["hydra:view"]["hydra:previous"]
        }
      }
    },
    nextPage(){
      this.setCurrentPage(this.next)
      this.towns = []
      this.getTowns()
    },
    previousPage(){
      this.setCurrentPage(this.previous)
      this.towns = []
      this.getTowns()
    },
    navigate(page){
      this.page = page
      this.towns = []
      this.showDataLoader = true
      this.initUrl()
      this.getTowns()
    },
    setCurrentPage(url){
      const currentPage = Number(url.split('page=')[1])
      if (currentPage !== this.page) this.page = currentPage
      this.url = url.split('api/')[1]
      this.showDataLoader = true
    },
    searchTown(query){
      this.query = query
      this.showDataLoader = true
      this.totalItems = 0
      this.towns = []
      this.initUrl()
      this.getTowns()
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader
        :title="'Ville'"
        :button-title="'+ ville'"
        :show-add-button="globals.roles.IS_SUPER_ADMIN"
        @clickButton="openAddTownModal"
        @submitSearch="searchTown"
    />
    <town-form-modal
        @onModalClose="closeModal"
        @townCreated="updateTownTable"
        :active-town="activeTown"
        :title="'Créer Une Ville'"
        v-model="entityFormModal"
    />
    <DataLoader v-if="showDataLoader"/>
    <section>
      <div class="" v-if="towns.length > 0">
        <div class="card">
          <div class="card-body">
            <town-table-card
                :towns="towns"
                @openUpdateTownModal="openUpdateTownModal"
            />
            <Pagination
                :next="next"
                :previous="previous"
                :currentPage="page"
                :totalItems="totalItems"
                @navigate="navigate"
                @nextPage="nextPage"
                @previousPage="previousPage"
            />
          </div>
        </div>
      </div>
    </section>
  </Layout>
</template>