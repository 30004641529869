<script>

import API from "@/plugins/axios-method";
import {inject} from "vue";

export default {
  name: 'town-table-card',
  components: {},
  props: {
    towns: {
      type: Array,
      default(){
        return []
      }
    },
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  setup(){
    const globals = inject('globals')

    return {globals}
  },
  data(){
    return {
    }
  },
  methods: {
    openUpdateTownModal(t){
      this.$emit('openUpdateTownModal', t)
    },
    updateStatus(id,status){

      const confirmation = confirm("Voulez-vous vraiment changer le statut de cette ville ?")

      if (!confirmation) return window.location.reload()

      API.patch(`/admin/cities/${id}/status`, {
        status: Number(!status)
      })
          .then(() => {

          })
          .catch(error => {
            window.location.reload()
            console.log(error)
          })
    },
  }
}
</script>

<template>
  <div class="table-responsive">
    <table class="table table-hover table-striped align-middle table-nowrap mb-0">
      <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Nom</th>
        <th scope="col">Statut</th>
        <th scope="col" v-if="globals.roles.IS_SUPER_ADMIN">Action</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(t, index) in towns" :key="index">
        <th scope="row">{{index + 1}}</th>
        <td>{{ t.name }}</td>
        <td>
          <div class="form-check form-switch">
            <input class="form-check-input" @change="updateStatus(t.uniqId, t.status)" type="checkbox" role="switch" :id="'SwitchCheck'+ index" :checked="t.status">
          </div>
        </td>
        <td v-if="globals.roles.IS_SUPER_ADMIN">
          <div class="hstack gap-3 fs-15">
            <a href="javascript:void(0);" role="button" @click="openUpdateTownModal(t)" class="link-primary"><i class="bx bx-pencil"></i></a>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped>

</style>