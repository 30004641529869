<script>
import Modal from "@/components/modal.vue";
import Axios from "@/plugins/axios";
import API from "@/plugins/axios-method";
import { helpers, required} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default {
  name: 'town-form-modal',
  components: {Modal},
  props: {
    title: {
      type: String,
      default: "Créer Une Ville"
    },
    value: {},
    activeTown: {
      type: Object,
      default(){
        return null
      }
    },
  },
  setup(){
    return { v$: useVuelidate() }
  },
  data(){
    return {
      visible: false,
      selectedRegion: null,
      loadRegions: false,
      regions: [],
      name: null,
      id: null,
      isSubmitting: false,
      isSubmitted: false,
    }
  },
  mounted() {
    this.getRegions()
  },
  watch: {
    value(newValue) {
      this.visible = newValue
    },
    visible(newValue){
      this.visible = newValue
    },
    activeTown(){
      if (this.activeTown){
        this.id = this.activeTown.uniqId
        this.name = this.activeTown.name
        this.selectedRegion = this.activeTown.region['@id']
      }
    }
  },
  methods: {
    getRegions(){
      API.get('regions')
          .then(response => {
            response.data['hydra:member'].map(r => {
              this.regions.push(r)
            })
          })
          .finally(() => {
            this.loadRegions = false
          })
    },
    onModalClose(){
      this.clearForm()
      this.visible = false
      this.isSubmitted = false
      this.isSubmitting = false
      this.$emit('onModalClose', 'town-form')
    },
    formSubmit(){
      if (this.activeTown){
        this.updateTown()
      }else{
        this.createTown()
      }
    },
    createTown(){
      if (this.validateForm()) return
      this.isSubmitted = true

      Axios.post('/admin/cities', {
        name: this.name,
        region: this.selectedRegion
      })
          .then(response => {
            if (response.status === 201){
              this.createSuccess(response.data)
            }
          })
          .catch(() => {
            this.isSubmitted = false
          })

    },
    updateTown(){
      if (this.validateForm()) return
      this.isSubmitted = true

      API.patch('/admin/cities/' + this.id, {
        name: this.name,
      })
          .then(response => {
            if (response.status === 200){
              this.createSuccess(response.data)
            }
          })
          .catch(() => {
            this.isSubmitted = false
          })
    },
    createSuccess(t){
      this.isSubmitting = false
      this.isSubmitted = false
      this.clearForm()
      this.onModalClose()
      this.$emit('townCreated', t)
    },
    clearForm(){
      this.v$.$reset()
      this.name = null
    },
    validateForm(){
      this.v$.$validate()
      return !!this.v$.$error
    },
  },
  validations: {
    name: {
      required: helpers.withMessage("Le nom est requis", required),
    },
    selectedRegion: {
      required: helpers.withMessage("La region est requise", required)
    }
  }
}
</script>

<template>
  <Modal :title="title" @closeModal="onModalClose" v-model="visible">
    <div class="">
      <form @submit.prevent="formSubmit">
        <div class="mb-3">
          <label for="telephone" class="form-label">Region</label>
          <select :disabled="loadRegions" :class="{'is-invalid': v$.selectedRegion.$errors.length }" class="form-select mb-3" v-model="selectedRegion">
            <option v-for="(r, index) in regions" :key="index" :value="r['@id']">{{r.name}}</option>
          </select>
          <div class="invalid-feedback" v-for="error in v$.selectedRegion.$errors" :key="error.$uid">
            <span>{{ error.$message }}</span>
          </div>
        </div>
        <div class="mb-3">
          <label for="telephone" class="form-label">Nom</label>
          <b-form-input type="text" placeholder="Nom" :class="{'is-invalid': v$.name.$errors.length }" v-model.trim="name"/>
          <div class="invalid-feedback" v-for="error in v$.name.$errors" :key="error.$uid">
            <span>{{ error.$message }}</span>
          </div>
        </div>
        <div class="mt-4">
          <button :disabled="isSubmitted" class="btn btn-success w-100" type="submit">
            <div v-if="isSubmitted" style="width: 20px; height: 20px" class="text-white spinner-border text-primary avatar-sm" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            <span v-if="!isSubmitted">Enregistrer</span>
          </button>
        </div>
      </form>
    </div>
  </Modal>

</template>

<style>

</style>